<script>
import Layout from "../layouts/auth";
import Repository from "../../app/repository/repository-factory";
import Vue from "vue";

const UserAccountRepository = Repository.get("UserAccountRepository");

export default {
  components: { Layout },
  data() {
    return {
      iconPath: require(process.env.VUE_APP_ICON_PATH),
      orgName: process.env.VUE_APP_ORG_NAME,
      form: {
        email: ""
      }
    };
  },
  computed: {},
  methods: {
    resetPassword() {
      UserAccountRepository.resetPassword(this.form).then(() => {

        if (this.form.email.length < 6) {
          return false;
        }

        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Jeżeli e-mail jest prawidłowy, to za chwilę otrzymasz wiadomość z dalszymi instrukcjami.',
          showConfirmButton: false,
          timer: 3500,
          onClose: () => {
            this.form.email = '';
          }
        });
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
      })
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Zmiana hasła - krok 1</h5>
                  <p>Wpisz swój adres e-mail, aby przejść do kolejnych kroków.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-white">
                    <img :src="iconPath" alt height="52" />
                  </span>
                </div>
              </router-link>
            </div>
            <div class="p-2">
              <div class="alert alert-success text-center mb-4" role="alert">
                Aby odzyskać hasło, wpisz swój adres e-mail.
              </div>
                <div class="form-group">
                  <label for="useremail">E-mail</label>
                  <input
                    type="email"
                    v-model="form.email"
                    class="form-control"
                    id="useremail"
                    placeholder="Wpisz e-mail"
                  />
                </div>
                <div class="form-group row mb-0">
                  <div class="col-12 text-right">
                    <button class="btn btn-primary w-md" v-on:click="resetPassword" type="submit">Resetuj hasło</button>
                  </div>
                </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-5 text-center">
          <p>
            Jednak pamiętasz swoje hasło?
            <router-link tag="a" to="/login" class="font-weight-medium text-primary">Zaloguj się</router-link>
          </p>
          <p>
           © {{new Date().getFullYear()}} {{ orgName }}
            <i class="mdi mdi-heart text-danger"></i>
          </p>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
